import React, { useState } from "react";
import { HashRouter as Router, Route, Routes, Link } from "react-router-dom";
import Menu from "./components/Menu";
import OrderSummary from "./components/OrderSummary";
import Checkout from "./components/Checkout";
import "./App.css";
import { Toast } from "react-bootstrap";

const App = () => {
  const [order, setOrder] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [showToast, setShowToast] = useState(false);

  const addToOrder = (item) => {
    setOrder([...order, { ...item }]);
    setCurrentItem(item);
    toggleToast();
  };

  const toggleToast = () => {
    setShowToast(true);
    setTimeout(function () {
      setShowToast(false);
    }, 5000);
  };

  const removeFromOrder = (index) => {
    const newOrder = [...order];
    newOrder.splice(index, 1);
    setOrder(newOrder);
  };

  const updateOrderItem = (index, updatedItem) => {
    const newOrder = [...order];
    newOrder[index] = updatedItem;
    setOrder(newOrder);
  };

  const handleCheckout = (orderNumber, waitTime) => {
    alert(
      `Order Number: ${orderNumber}, Estimated Wait Time: ${waitTime} minutes`
    );
    setOrder([]);
  };

  const getTotalItems = () => {
    return order.length;
  };

  return (
    <>
      {showToast ? (
        <Toast className="item-toast">
          <Toast.Body>
            {currentItem?.comboName
              ? currentItem?.comboName
              : currentItem?.name}{" "}
            was successfully added to your order!
          </Toast.Body>
        </Toast>
      ) : null}

      <Router>
        <header>
          <img
            src={`${process.env.PUBLIC_URL}/images/blo.png`}
            alt="Burney's Hot Chicken"
            width="200"
          />
          {/* <h1>Burney's Hot Chicken</h1> */}
        </header>
        <nav>
          <Link to="/">Menu</Link>
          <Link to="/order-summary">Order Summary ({getTotalItems()})</Link>
          <Link to="/checkout">Checkout</Link>
        </nav>
        <Routes>
          <Route path="/" element={<Menu addToOrder={addToOrder} />} />
          <Route
            path="/order-summary"
            element={
              <OrderSummary
                order={order}
                removeFromOrder={removeFromOrder}
                updateOrderItem={updateOrderItem}
              />
            }
          />
          <Route
            path="/checkout"
            element={<Checkout order={order} onCheckout={handleCheckout} />}
          />
        </Routes>
        <footer>
          <p>Phone: (123) 456-7890</p>
          <p>
            <a
              href="https://www.yelp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Yelp
            </a>
          </p>
          <p>Email: info@burneyshotchicken.com</p>
        </footer>
      </Router>
    </>
  );
};

export default App;
