import React, { useState } from "react";

const EditOrder = ({ item, index, updateOrderItem, closeEdit }) => {
  const [updatedItem, setUpdatedItem] = useState(item);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedItem({ ...updatedItem, [name]: value });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    let priceDifference = 0;

    if (name === "frySize") {
      priceDifference = value - item.frySize;
      setUpdatedItem({
        ...updatedItem,
        [name]: value,
        price: updatedItem.price + priceDifference,
      });
    }

    if (name === "drinkSize") {
      priceDifference = value - item.drinkSize;
      setUpdatedItem({
        ...updatedItem,
        [name]: value,
        price: updatedItem.price + priceDifference,
      });
    }
  };

  const handleSave = () => {
    updateOrderItem(index, updatedItem);
    closeEdit();
  };

  return (
    <div className="edit-order">
      <h2>Edit Order Item</h2>
      <label>
        Fry Size:
        <select
          name="frySize"
          value={updatedItem.frySize}
          onChange={handleSelectChange}
        >
          <option value="3">Small - $3</option>
          <option value="5">Medium - $5</option>
          <option value="6">Large - $6</option>
        </select>
      </label>
      <label>
        Drink Size:
        <select
          name="drinkSize"
          value={updatedItem.drinkSize}
          onChange={handleSelectChange}
        >
          <option value="3">Small - $3</option>
          <option value="4">Medium - $4</option>
          <option value="5">Large - $5</option>
        </select>
      </label>
      <label>
        Special Instructions:
        <textarea
          name="specialInstructions"
          value={updatedItem.specialInstructions || ""}
          onChange={handleInputChange}
        />
      </label>
      <button onClick={handleSave}>Save</button>
      <button onClick={closeEdit}>Cancel</button>
    </div>
  );
};

export default EditOrder;
