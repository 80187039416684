import React from "react";

const Checkout = ({ order, onCheckout }) => {
  const handleCheckout = () => {
    const orderNumber = Math.floor(Math.random() * 1000000);
    const waitTime = Math.floor(Math.random() * 30) + 10; // random wait time between 10 and 40 minutes
    onCheckout(orderNumber, waitTime);
  };

  return (
    <div>
      <h1>Checkout</h1>
      <ul>
        {order.map((item, index) => (
          <li key={index}>
            {item.name} - ${item.price.toFixed(2)}
          </li>
        ))}
      </ul>
      <h2>
        Total: $
        {order.reduce((total, item) => total + item.price, 0).toFixed(2)}
      </h2>
      <button onClick={handleCheckout}>Place Order</button>
    </div>
  );
};

export default Checkout;
