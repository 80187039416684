import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";

const Menu = ({ addToOrder }) => {

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleAddToOrderClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);
  const menuSections = [
    {
      // Placeholder images. Default: https://via.placeholder.com/100 as was before
      title: "Sliders",
      items: [
        {
          comboName: "Single Slider Combo",
          name: "1 Slider, Small Fries and Small Drink",
          price: 8.99,
          image: `${process.env.PUBLIC_URL}/images/slidercombo.jpg`,
        },
        {
          comboName: "Double Slider Combo",
          name: "2 Sliders, Medium Fries and Medium drink",
          price: 12.99,
          image: `${process.env.PUBLIC_URL}/images/slidercombo.jpg`,
        },
        {
          comboName: "Triple Slider Combo",
          name: "3 Sliders, Large Fry and Large Drink",
          price: 15.99,
          image: `${process.env.PUBLIC_URL}/images/slidercombo.jpg`,
        },
        {
          comboName: "Buddy Combo",
          name: "4 Sliders, 2 Large Fries and 2 Large Drinks",
          price: 24.99,
          image: `${process.env.PUBLIC_URL}/images/slidercombo.jpg`,
        },
      ],
    },
    {
      title: "Tenders",
      items: [
        {
          comboName: "Triple Tender Combo",
          name: "3 Chicken Tenders, Small Fries and Small Drink",
          price: 10.99,
          image: `${process.env.PUBLIC_URL}/images/slidercombo.jpg`,
        },
        {
          comboName: "Quad Tender Combo",
          name: "4 Chicken Tenders, Medium Fries and Medium Drink",
          price: 14.99,
          image: `${process.env.PUBLIC_URL}/images/slidercombo.jpg`,
        },
        {
          comboName: "Six Tender Combo",
          name: "6 Chicken Tenders, Large Fries and Large Drink",
          price: 19.99,
          image: `${process.env.PUBLIC_URL}/images/slidercombo.jpg`,
        },
        {
          comboName: "Triple Tender + Mini Waffles Combo",
          name: "3 Chicken Tenders, 4 Mini Waffles and Small Drink",
          price: 12.99,
          image: `${process.env.PUBLIC_URL}/images/slidercombo.jpg`,
        },
        {
          comboName: "Six Tender + Mini Waffles Combo",
          name: "6 Chicken Tenders, 8 Mini Waffles and Medium Drink",
          price: 22.99,
          image: `${process.env.PUBLIC_URL}/images/slidercombo.jpg`,
        },
      ],
    },
    {
      title: "Drinks",
      items: [
        {
          name: "Lemonade",
          price: 3,
          image: "https://cdn.loveandlemons.com/wp-content/uploads/2022/06/lemonade.jpg",
        },
        {
          name: "Iced Tea",
          price: 3,
          image: "https://www.alyonascooking.com/wp-content/uploads/2019/04/iced-tea-recipe-1-480x270.jpg",
        },
        {
          name: "Shaved Ice",
          price: 5,
          image: "https://i0.wp.com/hawaiianice.com/wp-content/uploads/2020/11/RainbowShaveIce.jpg?fit=1500%2C1000&ssl=1",
        },
        {
          name: "Canned Soda",
          price: 2,
          image: "https://www.thaibloom.com/wp-content/uploads/assorted-soda.png",
        },
        {
          name: "Bottled Water",
          price: 3,
          image: "https://www.healthdigest.com/img/gallery/is-drinking-from-a-frozen-plastic-water-bottle-safe/intro-1638892768.jpg",
        },
      ],
    },
    {
      title: "Ala Carte",
      items: [
        {
          name: "French Fries",
          price: 3,
          image: "https://thesaltedpotato.com/wp-content/uploads/2022/04/twice-fried-fries-500x375.jpg",
        },
        {
          name: "Mini Waffles",
          price: 4,
          image: "https://www.thekitchenmagpie.com/wp-content/uploads/images/2013/12/minidoughnutwaffles7.jpg",
        },
        {
          name: "Hot Dog",
          price: 4,
          image: "https://qvc.scene7.com/is/image/QVC/m/06/m96706.001",
        },
        {
          name: "Hot Chicken Slider",
          price: 6,
          image: "https://potatorolls.com/wp-content/uploads/Hot-Honey-Chicken10.jpg",
        },
      ],
    },
    {
      title: "Holiday Sides",
      items: [
        {
          name: "Mac 'N Cheese",
          price: 7,
          image: "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2020/10/14/0/FNK_SOUTHERN_STYLE_MAC_N_CHEESE_H_f_s4x3.jpg.rend.hgtvcom.1280.720.suffix/1602697013202.webp",
        },
        {
          name: "Seasoned Collard Greens",
          price: 4,
          image: "https://cdn.apartmenttherapy.info/image/upload/v1697652639/k/Photo/Recipes/2023-10-collard-greens/collard-greens-055-horizontal.jpg",
        },
        {
          name: "Banana Pudding",
          price: 6,
          image: "https://cdn.apartmenttherapy.info/image/upload/f_jpg,q_auto:eco,c_fill,g_auto,w_1500,ar_4:3/k%2FPhoto%2FRecipes%2F2024-03-banana-pudding%2Fbanana-pudding-696_1",
        },
      ],
    },
    {
      title: "Desserts",
      items: [
        {
          name: "Candied Grapes",
          price: 5,
          image: "https://www.housefullofmonkeys.com/wp-content/uploads/2023/03/candied-grapes-1.jpg",
        },
      ],
    },
  ];

  return (
    <Container>
      {menuSections.map((section, sectionIndex) => (
        <div key={sectionIndex} className="menu-section mb-5">
          <h2 className="menu-section-title mb-4">{section.title.toUpperCase()}</h2>
          <Row>
            {section.items.map((item, index) => (
              <Col key={index} sm={6} md={4} lg={3} className="mb-4">
                <Card style={{width: "100%"}} className="menu-item">
                  <Card.Img variant="top" src={item.image} style={{height: "10rem", objectFit: "cover"}} />
                  <Card.Body>
                    {item.comboName ? (
                      <>
                        <Card.Title className="menu-item__title">{item.comboName}</Card.Title>
                        <Card.Subtitle className="text-muted">{item.name}</Card.Subtitle>
                      </>
                    ) : (
                      <Card.Title className="menu-item__title">{item.name}</Card.Title>
                    )}
                    <Card.Text>${item.price.toFixed(2)}</Card.Text>
                    { item.comboName ?  <Button variant="danger" onClick={() => handleAddToOrderClick(item)}>
                      Add to Order
                    </Button> : <Button variant="danger" onClick={() => addToOrder(item)}>Add to Order</Button> }
                   
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ))}

            <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Customize Your Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem && (
            <>
              <h4>{selectedItem.comboName || selectedItem.name}</h4>
              <img src={selectedItem.image} alt={selectedItem.name} className="img-fluid mb-3" />
              <p>{selectedItem.name}</p>
              <p>${selectedItem.price.toFixed(2)}</p>
              <h5>Choose Drink</h5>
              <Row>
                <Col xs={6}>
                  <Button variant="outline-primary" className="mb-2 w-100">Lemonade</Button>
                </Col>
                <Col xs={6}>
                  <Button variant="outline-primary" className="mb-2 w-100">Iced Tea</Button>
                </Col>
                <Col xs={6}>
                  <Button variant="outline-primary" className="mb-2 w-100">Canned Soda</Button>
                </Col>
                <Col xs={6}>
                  <Button variant="outline-primary" className="mb-2 w-100">Water</Button>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => {
            addToOrder(selectedItem);
            handleClose();
          }}>
            Add to Order
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Menu;
