import React, { useState } from "react";
import EditOrder from "./EditOrder";

const OrderSummary = ({ order, removeFromOrder, updateOrderItem }) => {
  const [editingIndex, setEditingIndex] = useState(null);

  const getTotal = () => {
    return order.reduce((total, item) => total + item.price, 0).toFixed(2);
  };

  return (
    <div>
      <h1>Order Summary</h1>
      <ul>
        {order.map((item, index) => (
          <li key={index}>
            {item.name} - ${item.price.toFixed(2)}
            <button onClick={() => removeFromOrder(index)}>Remove</button>
            <button onClick={() => setEditingIndex(index)}>Edit</button>
          </li>
        ))}
      </ul>
      <h2>Total: ${getTotal()}</h2>
      <button>Checkout</button>
      {editingIndex !== null && (
        <EditOrder
          item={order[editingIndex]}
          index={editingIndex}
          updateOrderItem={updateOrderItem}
          closeEdit={() => setEditingIndex(null)}
        />
      )}
    </div>
  );
};

export default OrderSummary;
